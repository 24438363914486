<div class="topbar clearfix octo-topbar" style="background-color: #6B8B9A;">

  <div class="version">
    <div class="text-logo" routerLink="/" tabindex="100">Anadolu Asistans</div>
    <!--<img class="logo" alt="AS" src="assets/images/anadolu/anadolu-logo.png" routerLink="/" />-->
    <div class="spacer kapatma"> </div>
    <img alt="octopus" class="kapatma" height="32" src="assets/images/octopus/octopus_logo_white.png">
    <div class="version-text">&nbsp;{{'v'+appSettings.version}}</div>
  </div>

  <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
    <i class="pi pi-bars octo-menu-icon"></i>
  </a>


 

  <div class="profile">
    <div  *ngIf="role !== 'supplier'" class="topbar-icon-container" (click)="openSearchDialog()">
      <i class="pi pi-search text-3xl p-mr-2 bell" pTooltip="Ara"></i>
    </div>
    <!-- <div class="topbar-icon-container" (click)="getServiceInfo()"
      *ngIf="role === 'supplier' && this.supplierServiceInfo?.length > 0">

      <i class="pi pi-file-import text-3xl p-mr-2 bell" *ngIf="this.supplierServiceInfo?.length > 0" pBadge
        severity="danger" [value]="this.supplierServiceInfo?.length"
        [ngClass]="{'blinking': this.supplierServiceInfo?.length > 0}">
        
      </i>

    </div> -->
    <div class="topbar-icon-containers tooltip-supplier-warning"*ngIf="role === 'supplier'" style="margin-top: -4px;">
      <i  class="pi pi-exclamation-triangle text-3xl p-mr-2 bell" *ngIf="optionChangesInformation.length > 0" pBadge
        severity="warning" [value]="this.optionChangesInformation?.length"
        (mouseover)="showOptionChanges = true" pTooltip="Hizmet Bilgilerinde Değişim">
      </i>

      <div *ngIf="showOptionChanges && optionChangesInformation.length > 0" class="tooltip">
        <div class="header">
          <div class="close-icon" (click)="showOptionChanges = false">X</div>
        </div>
        <div *ngFor="let info of optionChangesInformation">
          <div class="item">
            <a [routerLink]="['/supplier-operations/service-detail', info.operation.serviceId]" target="_blank" 
               [pTooltip]="info.operation.code + ' numaralı hizmete git'">
              <i class="pi pi-external-link"></i>
            </a>
            <span><b><a [routerLink]="['/supplier-operations/service-detail', info.operation.serviceId]" target="_blank">
              {{ info.operation.code }}
            </a></b> numaralı hizmet için opsiyon bilgisi <b>{{ info.newValue | uppercase }}</b> olarak değiştirildi.</span>
            <i class="pi pi-trash" (click)="removeItem(info)"></i>
          </div>
        </div>
      </div>      
    </div>
    
    <div class="topbar-icon-containers " [ngClass]="{'blinking': this.supplierServiceInfo?.length > 0}" *ngIf="role === 'supplier'" style="margin-top: -4px;">

      <p-button size="small" (click)="getServiceInfo()" severity="danger" label="Yeni Hizmet" [badge]="this.supplierServiceInfo?.length > 0 ? this.supplierServiceInfo?.length : this.testCount" />

    </div>
    <div class="topbar-icon-container" (click)="openNotesDialog()">
      <i class="pi pi-paperclip text-3xl p-mr-2 bell" *ngIf="noteCount !== 0" pBadge severity="warning"
        [value]="noteCount" pTooltip="Notlarım"></i>
      <i class="pi pi-paperclip text-3xl p-mr-2 bell" *ngIf="noteCount === 0" pTooltip="Notlarım"></i>
    </div>

    <div class="topbar-icon-container" (click)="goToAnnouncementAssignedList()">
      <i class="pi pi-megaphone text-3xl p-mr-2 bell" *ngIf="announcementValue !== 0" pBadge severity="warning"
        [value]="announcementValue" pTooltip="Duyurular"></i>
      <i class="pi pi-megaphone text-3xl p-mr-2 bell" *ngIf="announcementValue === 0" pTooltip="Duyurular"></i>
    </div>

    <div class="topbar-icon-container" (click)="goToTaskList()">
      <i class="pi pi-bell text-3xl p-mr-2 bell" *ngIf="taskValue !== 0" pBadge severity="warning"
        [value]="taskValue"  pTooltip="Açık Görevlerim"></i>
      <i class="pi pi-bell text-3xl p-mr-2 bell" *ngIf="taskValue === 0"  pTooltip="Görevlerim"></i>
    </div>


    <div class="topbar-icon-container" (click)="showScriptSearchDialog()">
      <i class="pi pi-file text-3xl p-mr-2 bell" *ngIf="role !== 'supplier'"  pTooltip="Scriptler"></i>
    </div>


    <a href="#" class="profile-badge" (click)="app.onTopbarMenuButtonClick($event)">
      <span class="username">{{ userName + (surname ? ' '+surname : '') }}</span>
      <i class="pi {{app.topbarMenuActive ? 'pi-angle-up' : 'pi-angle-down'}}" style="color: #fff"></i>
    </a>

    <!--
  <span class="topbar-search">
      <input type="text" pInputText placeholder="Search"/>
      <span class="pi pi-search"></span>
  </span>
  -->

    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">
      <li #profile [ngClass]="{'menuitem-active':app.activeTopbarItem === profile}">
        <div class="topbar-extra-info">
          <i class="pi pi-envelope"></i>
          <span>{{email}}</span>
        </div>
        <a [routerLink]="['/about']">
          <i class="pi pi-question-circle"></i>
          <span>Hakkında</span>
        </a>
        <!-- <a [routerLink]="['/reset-password']">
            <i class="topbar-icon pi pi-key"></i>
            <span class="topbar-item-name">Şifre Değiştir</span>
          </a> -->
        <a (click)="logout()">
          <i class="pi pi-sign-out"></i>
          <span>Güvenli Çıkış</span>
        </a>
      </li>
    </ul>

    <!--
  <ul *ngIf="showOnay" class="topbar-menu onaylarim fadeInDown" [ngClass]="{'topbar-menu-visible': app.topbarOnaylarimMenuActive}"
      style="width: 420px;">

      <div class="ui-g">
        <div class="ui-g-12 text-center">
          <button type="button" pButton label="Bütün Onaylarım" icon="pi pi-check" class="ui-button-danger"
                  (click)="onaylarim()"></button>
        </div>
      </div>

      <app-onaylarim (onaylarUpdated)="onaylarUpdated($event)"></app-onaylarim>
  </ul>-->
  </div>


</div>
  <!-- <app-operation-control (getServiceInfoFilter)="getServiceInfoFilter()"></app-operation-control> -->


  <app-self-notes (onTotalNotesChange)="changeNotesValue($event)" #notes></app-self-notes>
  <app-script-search #scriptSearch></app-script-search>