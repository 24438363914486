import { NgOtpInputComponent } from "ng-otp-input";
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { AuthService } from "src/app/services/auth/auth.service";
import { environment } from "../../../../environments/environment";
import { CountDownComponent } from "../../../components/countdown/countdown.component";
import { AppSettingsService } from "../../../services/settings/app-settings.service";
import { AuthPermissionService } from "../../../services/auth/auth-permission.service";
import { Location } from "@angular/common";
import { AnnouncementShowComponent } from "../../operator/announcement/components/announcement-show/announcement-show.component";
import { DialogService } from "primeng/dynamicdialog";
import { AnnouncementService } from "../../operator/announcement/services/announcement.service";
import { RecaptchaComponent } from "../../../components/recaptcha/recaptcha.component";
import { CelebrationComponent } from "./celebration/celebration.component";
import { UserService } from "../../user-management/services/user.service";
import { OccurenceParameterService } from "src/app/services/occurence-parameter.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit,AfterViewInit {
    @ViewChild("countdown") countDown: CountDownComponent;
    @ViewChild("recapthca",{ static: false }) recapthca: RecaptchaComponent;

    @ViewChild(NgOtpInputComponent, { static: false })
    ngOtpInput: NgOtpInputComponent;
    @ViewChild("captch", { static: false }) captcha;

    email = "";
    password = "";
    supplierId = null;
    errorMessage = "";
    submitted = false;

    userNameCaption = "E-posta adresi";


    lastCaptchaToken = "";

    submitBtnEnable = false;

    showCodePanel = false;
    otpCode: string;
    otpCodeMessage: { success: boolean; message: string };
    timeStatus = true;
    shownMessage = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        public appSettings: AppSettingsService,
        private authPermissionService: AuthPermissionService,
        private location: Location,
        private dialogService: DialogService,
        private announcementService: AnnouncementService,
        private cdr:ChangeDetectorRef,
        private userService: UserService,
        private parameters: OccurenceParameterService
    ) {
        this.shownMessage = (this.location.getState() as any)?.shownMessage;
    }

    ngOnInit(): void {
        if (this.appSettings.loginStrategy === "ldap") {
            this.userNameCaption = "Kullanıcı Adı";
        }
    }
    ngAfterViewInit(){
        if(this.appSettings.captchaGuard && this.recapthca){
            this.recapthca.start();
        }
    }

    getOtpCode() {
        const credentials = {
            email: this.email,
            password: this.password,
        };

        this.submitted = true;
        this.authService
            .getOtpCode(credentials)
            .pipe(
                catchError((err) => {
                    this.errorMessage = "Kullanıcı adı veya şifreniz hatalı!";
                    this.otpCodeMessage = { success: false, message: err };
                    return throwError(err);
                }),
                finalize(() => (this.submitted = false))
            )
            .subscribe((res) => {
                if (res.success) {
                    this.timeStatus = true;
                    this.otpCodeMessage = {
                        success: true,
                        message:
                            "Giriş kodu " +
                            res.phone +
                            " nolu telefona gönderildi.",
                    };
                    this.showCodePanel = true;

                    if (this.countDown) {
                        this.countDown.startCountDown(59);
                    }
                    if (this.otpCode) {
                        this.ngOtpInput.setValue("");
                    }
                }
            });
    }

    signIn() {
        this.otpCodeMessage = { success: undefined, message: "" };
        const credentials = {
            email: this.email,
            password: this.password,
            captchaToken: this.lastCaptchaToken,
            otpCode: this.otpCode,
        };

        this.authService
            .login(credentials)
            .pipe(
                catchError((err) => {
                    this.errorMessage = err;
                    if (["unauthorized", "Unauthorized"].includes(err)) {
                        this.errorMessage =
                            "Kullanıcı adı veya şifreniz hatalı!";
                        return throwError(this.errorMessage);
                    }
                    this.otpCodeMessage = { success: false, message: err };
                    this.captcha.reset();
                    return throwError(err);
                }),
                finalize(() => (this.submitted = false))
            )
            .subscribe((success) => {
                if (success) {
                    const userId = this.authService.getUserId();
                    this.authService.getUserRoles(userId).subscribe((res) => {
                        this.authPermissionService.setAuthPermissions(res);
                        this.router.navigate(["/"]).then(() => {
                            this.getAnnouncementsAssignedMe();
                            this.checkTodayBirthdaysAndFestiveDays();
                        });
                    });
                }
            });
    }

    submit(): void {
        this.errorMessage = "";
        this.otpCodeMessage = { success: undefined, message: "" };

        if (!this.email || !this.password) {
            this.errorMessage = "Kullanıcı adı ve şifre boş bırakılamaz.";
            return;
        }
        if (this.password.length < 8) {
            this.errorMessage = "Şifre 8 karakterden kısa olamaz.";
            return;
        }

        if (this.appSettings.twoFactorAuthentication) {
            this.getOtpCode();
        } else {
            this.signIn();
        }
    }

    showCaptchaResponse(e) {
        if(e){
            this.lastCaptchaToken = e;
            this.submitBtnEnable = true;
            this.cdr.detectChanges();
        }
    }

    expireCaptcha() {
        this.submitBtnEnable = false;
    }

    onOtpChange(otpCode) {
        this.otpCode = otpCode;
    }

    timeFinish() {
        this.timeStatus = false;
        this.otpCodeMessage = {
            success: false,
            message: "Kodun süresi doldu!",
        };
    }

    controlButtonDisable() {
        if (!this.appSettings.captchaGuard) {
            return false;
        }
        return this.submitted || !this.submitBtnEnable;
    }

    getAnnouncementsAssignedMe() {
        this.announcementService
            .getAnnouncementsAssignedMe()
            .subscribe((response) => {
                if (response && response.length > 0) {
                    response.forEach((announcement) => {
                        this.dialogService.open(AnnouncementShowComponent, {
                            style: { width: '50vw' },
                            data: {
                                announcementData: announcement,
                                readButton: true,
                            },
                            modal: true,
                            baseZIndex: 10000,
                        });
                    });
                }
            });
    }

    async checkTodayBirthdaysAndFestiveDays() {
      const checkFestive: boolean | undefined = await this.parameters.getValues("checkFestive").toPromise();
      if (checkFestive) {
        const festiveDays = [
          { date: '01-01', name: 'Yeni Yıl' },
          { date: '03-08', name: '8 Mart Dünya Kadınlar Günü' },
          { date: '04-23', name: '23 Nisan Ulusal Egemenlik ve Çocuk Bayramı' },
          { date: '05-01', name: '1 Mayıs Emek ve Dayanışma Günü' },
          { date: '07-15', name: '15 Temmuz Demokrasi ve Milli Birlik Bayramı' },
          { date: '08-30', name: '30 Ağustos Zafer Bayramı' },
          { date: '10-29', name: '29 Ekim Cumhuriyet Bayramı' }
      ];
  
      const today = new Date();
      const formattedToday = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  
        // Check if today is a festive day
        festiveDays.forEach(festiveDay => {
            if (festiveDay.date === formattedToday) {
                this.dialogService.open(CelebrationComponent, {
                    width: "578px",
                    data: {
                        celebrationData: { isFestiveDay: true, festiveName: festiveDay.name },
                    },
                    modal: true,
                    baseZIndex: 10000,
                });
            }
        });
      }

      // Fetch and check if today is someone's birthday
      this.userService
          .isMyBirthday()
          .subscribe((response: boolean) => {
              if (response) {
                this.dialogService.open(CelebrationComponent, {
                  width: "578px",
                  data: {
                      celebrationData: { 
                        isBirthday: true, 
                        name: this.authService.getUserName(), 
                        surName: this.authService.getUserSurname()
                      },
                  },
                  modal: true,
                  baseZIndex: 10000,
                });
              }
          });
  }
}
